<template>
  <v-form>
    <v-row>
      <v-col cols="12" md="6" sm="6">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          ปฎิทินการศึกษา - ปริญญาเอก
        </span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="6" sm="6" class="text-right">
        <v-btn
          @click="openDialogCalendar()"
          color="#2AB3A3"
          dark
          style="padding: 12px 16px; gap: 8px; width: 158px; height: 48px"
          >+ เพิ่มกิจกรรม</v-btn
        >

        <DialogCalendarPhD
          :dialogCalendar="dialogCalendar"
          @closeDialogCalendar="closeDialogCalendar"
          @pushEvent="pushEvent"
        />
        <DialogCalendarPhDEdit
          :itemEdit="itemEdit"
          :openDialogEdit="openDialogEdit"
          :selectedEvent="selectedEvent"
          @closeDialogEdit="closeDialogEdit"
          @editEvent="editEvent"
        />
      </v-col>
    </v-row>
    <v-row class="fill-height mt-n2">
      <v-col cols="12" md="12" sm="12">
        <v-card outlined class="pa-6">
          <v-row>
            <v-col>
              <v-sheet height="64">
                <v-toolbar flat>
                  <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                  >
                    Today
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small> mdi-chevron-left </v-icon>
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right> mdi-menu-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="type = 'day'">
                        <v-list-item-title>Day</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'week'">
                        <v-list-item-title>Week</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>Month</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 days</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="600">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  :events="events"
                  :event-color="getEventColor"
                  :type="type"
                  @click:event="showEvent"
                  @click:more="viewDay"
                  @click:date="viewDay"
                  @change="updateRange"
                >
                  <!-- <template v-slot:[`event.name_th`]="{ event }">
                    {{ event.name_th }}
                  </template> -->
                </v-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-x
                >
                  <v-card color="grey lighten-4" min-width="350px" flat>
                    <v-toolbar :color="selectedEvent.color" dark>
                      <v-btn icon>
                        <v-icon @click="deleteItemCalendar(selectedEvent)"
                          >mdi-delete</v-icon
                        >
                      </v-btn>
                      <v-btn icon @click="checkDate(selectedEvent)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-toolbar-title
                        v-html="selectedEvent.name"
                      ></v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon>
                        <v-icon>mdi-heart</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <span>{{ selectedEvent.desc_th }}</span>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        text
                        color="secondary"
                        @click="selectedOpen = false"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10" sm="10">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          เอกสารปฎิทินการศึกษา - ปริญญาเอก
        </span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="2" sm="2" class="text-right">
        <DialogCalendarPhD1 @getAllType1="getAllType1" />
        <DialogCalendarPhD1Edit
          :openDialog1="openDialog1"
          :itemEdit1="itemEdit1"
          @getAllType1="getAllType1"
          @closeDialog1="closeDialog1"
        />
      </v-col>
    </v-row>
    <v-row class="mt-n3">
      <v-col cols="12" md="12" sm="12">
        <v-card class="pa-6" flat>
          <v-data-table
            :headers="headersCalender1"
            :items="items1"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.img_url`]="{ item }">
              <v-img :src="item.img_url" width="120px" class="ma-1"></v-img>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon @click="copyDoc1(item)">mdi-content-copy</v-icon>
              <v-icon @click="updateDialog1(item)">mdi-pencil</v-icon>
              <v-icon @click="DeleteItems(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:body="props" v-if="isWindowWidthGreaterThan768">
              <draggable
                :list="props.items"
                tag="tbody"
                @change="DragItems1(props.items)"
              >
                <tr v-for="(item, index) in props.items" :key="index">
                  <td v-if="isWindowWidthGreaterThan768">
                    <v-icon small class="page__grab-icon">
                      mdi-arrow-all
                    </v-icon>
                  </td>
                  <td class="text-start">{{ item.index }}</td>
                  <td class="text-start">{{ item.name_th }}</td>

                  <td class="text-start">
                    <v-row justify="center">
                      <v-icon @click="copyDoc1(item)">mdi-content-copy</v-icon>
                      <v-icon @click="updateDialog1(item)">mdi-pencil</v-icon>
                      <v-icon @click="DeleteItems(item)">mdi-delete</v-icon>
                    </v-row>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10" sm="10">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          เอกสารตารางเรียน - ปริญญาเอก
        </span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="2" sm="2" class="text-right">
        <DialogCalendarPhD2 @getAllType2="getAllType2" />
        <DialogCalendarPhD2Edit
          :openDialog2="openDialog2"
          :itemEdit2="itemEdit2"
          @getAllType2="getAllType2"
          @closeDialog2="closeDialog2"
        />
      </v-col>
    </v-row>
    <v-row class="mt-n3">
      <v-col cols="12" md="12" sm="12">
        <v-card class="pa-6" flat>
          <v-data-table
            :headers="headersCalender1"
            :items="items2"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.img_url`]="{ item }">
              <v-img :src="item.img_url" width="120px" class="ma-1"></v-img>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon @click="copyDoc2(item)">mdi-content-copy</v-icon>
              <v-icon @click="updateDialog2(item)">mdi-pencil</v-icon>
              <v-icon @click="DeleteItems(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:body="props" v-if="isWindowWidthGreaterThan768">
              <draggable
                :list="props.items"
                tag="tbody"
                @change="DragItems2(props.items)"
              >
                <tr v-for="(item, index) in props.items" :key="index">
                  <td v-if="isWindowWidthGreaterThan768">
                    <v-icon small class="page__grab-icon">
                      mdi-arrow-all
                    </v-icon>
                  </td>
                  <td class="text-start">{{ item.index }}</td>
                  <td class="text-start">{{ item.name_th }}</td>

                  <td class="text-start">
                    <v-row justify="center">
                      <v-icon @click="copyDoc2(item)">mdi-content-copy</v-icon>
                      <v-icon @click="updateDialog2(item)">mdi-pencil</v-icon>
                      <v-icon @click="DeleteItems(item)">mdi-delete</v-icon>
                    </v-row>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10" sm="10">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          เอกสารตารางสอบ - ปริญญาเอก
        </span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="2" sm="2" class="text-right">
        <DialogCalendarPhD3 @getAllType3="getAllType3" />
        <DialogCalendarPhD3Edit
          :openDialog3="openDialog3"
          :itemEdit3="itemEdit3"
          @getAllType3="getAllType3"
          @closeDialog3="closeDialog3"
        />
      </v-col>
    </v-row>
    <v-row class="mt-n3">
      <v-col cols="12" md="12" sm="12">
        <v-card class="pa-6" flat>
          <v-data-table
            :headers="headersCalender1"
            :items="items3"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.img_url`]="{ item }">
              <v-img :src="item.img_url" width="120px" class="ma-1"></v-img>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon @click="copyDoc3(item)">mdi-content-copy</v-icon>
              <v-icon @click="updateDialog3(item)">mdi-pencil</v-icon>
              <v-icon @click="DeleteItems(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:body="props" v-if="isWindowWidthGreaterThan768">
              <draggable
                :list="props.items"
                tag="tbody"
                @change="DragItems3(props.items)"
              >
                <tr v-for="(item, index) in props.items" :key="index">
                  <td v-if="isWindowWidthGreaterThan768">
                    <v-icon small class="page__grab-icon">
                      mdi-arrow-all
                    </v-icon>
                  </td>
                  <td class="text-start">{{ item.index }}</td>
                  <td class="text-start">{{ item.name_th }}</td>

                  <td class="text-start">
                    <v-row justify="center">
                      <v-icon @click="copyDoc3(item)">mdi-content-copy</v-icon>
                      <v-icon @click="updateDialog3(item)">mdi-pencil</v-icon>
                      <v-icon @click="DeleteItems(item)">mdi-delete</v-icon>
                    </v-row>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import DialogCalendarPhD from "../Dialogs/DialogCalendarPhD.vue";
import DialogCalendarPhDEdit from "../Dialogs/DialogCalendarPhD0Edit.vue";
import DialogCalendarPhD1 from "../Dialogs/DialogCalendarPhD1.vue";
import DialogCalendarPhD1Edit from "../Dialogs/DialogCalendarPhD1Edit.vue";
import DialogCalendarPhD2 from "../Dialogs/DialogCalendarPhD2.vue";
import DialogCalendarPhD2Edit from "../Dialogs/DialogCalendarPhD2Edit.vue";
import DialogCalendarPhD3 from "../Dialogs/DialogCalendarPhD3.vue";
import DialogCalendarPhD3Edit from "../Dialogs/DialogCalendarPhD3Edit.vue";

export default {
  components: {
    draggable,
    Loading,
    DialogCalendarPhD,
    DialogCalendarPhDEdit,
    DialogCalendarPhD1,
    DialogCalendarPhD1Edit,
    DialogCalendarPhD2,
    DialogCalendarPhD2Edit,
    DialogCalendarPhD3,
    DialogCalendarPhD3Edit,
  },
  data() {
    return {
      openDialog1: false,
      openDialog2: false,
      openDialog3: false,
      itemEdit1: {},
      itemEdit2: {},
      itemEdit3: {},
      items1: [],
      items2: [],
      items3: [],

      startMenu: false,
      endMenu: false,
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      dialogCalendar: false,
      openDialogEdit: false,
      itemEdit: {},

      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],

      headersCalender: [],
      headersCalender1: [
        { text: "", value: "", align: "start" },
        { text: "ลำดับ", value: "index", align: "start" },
        { text: "ชื่อไฟล์", value: "name_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersCalender2: [
        { text: "", value: "", align: "start" },
        { text: "ลำดับ", value: "index", align: "start" },
        { text: "ชื่อไฟล์", value: "name_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    document.title = "ตารางการศึกษา (ป.เอก) - GSCM Management System";
  },
  mounted() {
    this.getAllTable();
    this.$refs.calendar.checkChange();
    this.getAllType1();
    this.getAllType2();
    this.getAllType3();

    window.scrollTo(0, 0);

    if (this.isWindowWidthGreaterThan768) {
      this.headersCalender = this.headersCalender1;
    } else {
      this.headersCalender = this.headersCalender2;
    }
  },
  methods: {
    async DragItems1(val) {
      this.loading = true;
      console.log("DragItems", this.items1);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      for (let i in val) {
        val[i].index = parseInt(i) + 1;
      }

      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/carlendars/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      const response1 = await this.axios.get(
        `${process.env.VUE_APP_API}/carlendars?types=2&&table_name=ปฎิทินการศึกษา`,
        auth
      );
      console.log("findAllUpdateIndex", response1.data.data);
      this.items1 = response1.data.data;
      this.loading = false;
    },
    async DragItems2(val) {
      this.loading = true;
      console.log("DragItems", this.items2);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      for (let i in val) {
        val[i].index = parseInt(i) + 1;
      }

      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/carlendars/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      const response1 = await this.axios.get(
        `${process.env.VUE_APP_API}/carlendars?types=2&&table_name=ตารางเรียน`,
        auth
      );
      console.log("findAllUpdateIndex", response1.data.data);
      this.items2 = response1.data.data;
      this.loading = false;
    },
    async DragItems3(val) {
      this.loading = true;
      console.log("DragItems", this.items3);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      for (let i in val) {
        val[i].index = parseInt(i) + 1;
      }

      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/carlendars/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      const response1 = await this.axios.get(
        `${process.env.VUE_APP_API}/carlendars?types=2&&table_name=ตารางสอบ`,
        auth
      );
      console.log("findAllUpdateIndex", response1.data.data);
      this.items3 = response1.data.data;
      this.loading = false;
    },
    async copyDoc1(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/carlendars/${val.id}`,
        auth
      );
      this.itemEdit1 = response.data.data;
      console.log("itemEdit1", response.data.data);

      const data = {
        name_th: this.itemEdit1.name_th,
        name_en: this.itemEdit1.name_en,
        types: this.itemEdit1.types,
        documentFiles: this.itemEdit1.documentFiles,
        table_name: this.itemEdit1.table_name,
      };
      console.log("data", data);
      const response1 = await this.axios.post(
        `${process.env.VUE_APP_API}/carlendars`,
        data,
        auth
      );
      console.log("response1", response1.data.data);

      if (response1.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `คัดลอกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getAllType1();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response1.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    async copyDoc2(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/carlendars/${val.id}`,
        auth
      );
      this.itemEdit2 = response.data.data;
      console.log("itemEdit2", response.data.data);

      const data = {
        name_th: this.itemEdit2.name_th,
        name_en: this.itemEdit2.name_en,
        types: this.itemEdit2.types,
        documentFiles: this.itemEdit2.documentFiles,
        table_name: this.itemEdit2.table_name,
      };
      console.log("data", data);
      const response1 = await this.axios.post(
        `${process.env.VUE_APP_API}/carlendars`,
        data,
        auth
      );
      console.log("response1", response1.data.data);

      if (response1.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `คัดลอกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getAllType2();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response1.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    async copyDoc3(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/carlendars/${val.id}`,
        auth
      );
      this.itemEdit3 = response.data.data;
      console.log("itemEdit3", response.data.data);

      const data = {
        name_th: this.itemEdit3.name_th,
        name_en: this.itemEdit3.name_en,
        types: this.itemEdit3.types,
        documentFiles: this.itemEdit3.documentFiles,
        table_name: this.itemEdit3.table_name,
      };
      console.log("data", data);
      const response1 = await this.axios.post(
        `${process.env.VUE_APP_API}/carlendars`,
        data,
        auth
      );
      console.log("response1", response1.data.data);

      if (response1.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `คัดลอกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getAllType3();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response1.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    async updateDialog1(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/carlendars/${val.id}`,
        auth
      );
      console.log("itemEdit1", response.data.data);
      this.itemEdit1 = response.data.data;

      this.openDialog1 = true;
    },
    async updateDialog2(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/carlendars/${val.id}`,
        auth
      );
      console.log("itemEdit2", response.data.data);
      this.itemEdit2 = response.data.data;

      this.openDialog2 = true;
    },
    async updateDialog3(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/carlendars/${val.id}`,
        auth
      );
      console.log("itemEdit3", response.data.data);
      this.itemEdit3 = response.data.data;

      this.openDialog3 = true;
    },
    async getAllType1() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/carlendars?types=2&&table_name=ปฎิทินการศึกษา`,
        auth
      );
      console.log("getAllType1", response.data.data);
      this.items1 = response.data.data;
      for (let i in this.items1) {
        this.items1[i].index = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async getAllType2() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/carlendars?types=2&&table_name=ตารางเรียน`,
        auth
      );
      console.log("getAllType2", response.data.data);
      this.items2 = response.data.data;
      for (let i in this.items2) {
        this.items2[i].index = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async getAllType3() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/carlendars?types=2&&table_name=ตารางสอบ`,
        auth
      );
      console.log("getAllType3", response.data.data);
      this.items3 = response.data.data;
      for (let i in this.items3) {
        this.items3[i].index = parseInt(i) + 1;
      }
      this.loading = false;
    },
    viewDay({ date }) {
      this.focus = date;
      this.dialogCalendar = true;
      // this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
      console.log("event", event);
    },
    checkDate(val) {
      console.log("val", val);
      this.itemEdit = val;

      console.log("this.events", this.events);

      this.openDialogEdit = true;

      this.selectedOpen = false;
    },
    deleteItemCalendar(val) {
      // Delete Item Code //
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/calendarAcademics/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          // this.$router.go(0);
          // this.getAll1();
          this.getAllTable();
        }
      });
      // this.events = itemFilter;

      this.selectedOpen = false;
    },
    updateRange({ start, end }) {
      // const events = [];
      // const min = new Date(`${start.date}T00:00:00`);
      // const max = new Date(`${end.date}T23:59:59`);
      // const days = (max.getTime() - min.getTime()) / 86400000;
      // const eventCount = this.rnd(days, days + 20);
      // for (let i = 0; i < eventCount; i++) {
      //   const allDay = this.rnd(0, 3) === 0;
      //   const firstTimestamp = this.rnd(min.getTime(), max.getTime());
      //   const first = new Date(firstTimestamp - (firstTimestamp % 900000));
      //   console.log("first", first);
      //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
      //   const second = new Date(first.getTime() + secondTimestamp);
      //   events.push({
      //     name: this.names[this.rnd(0, this.names.length - 1)],
      //     start: first,
      //     end: second,
      //     color: "rgb(42, 179, 163)",
      //     timed: !allDay,
      //   });
      // }
      // this.events = events;
    },
    async sendApi() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        ...this.events,
        types: 2,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/carlendarTable`,
        data,
        auth
      );
      console.log("calendarTable", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `Success`,
          showConfirmButton: false,
          timer: 1500,
        });
        // this.$router.go(0);
      } else {
        this.$swal.fire({
          icon: "error",
          text: response.data.response_description,
          showConfirmButton: false,
          timer: 1500,
        });
        // this.$router.go(0);
      }
    },

    async getAllTable() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/calendarAcademics?types=ปริญญาเอก`,
        auth
      );
      console.log("calendarAcademics", response.data.data);
      this.events = response.data.data;
      this.loading = false;
    },
    async pushEvent(
      startDate,
      endDate,
      name_th,
      name_en,
      desc_th,
      desc_en,
      timeStart,
      timeEnd
    ) {
      let dateStart = new Date(startDate);
      let dateEnd = new Date(endDate);
      const allDay = this.rnd(0, 3) === 0;

      this.events.push({
        name: name_th,
        name_th: name_th,
        start: dateStart,
        end: dateEnd,
        color: "rgb(42, 179, 163)",
        timed: !allDay,
        name_en: name_en,
        desc_th: desc_th,
        desc_en: desc_en,
        timeStart,
        timeEnd,
        types: "ปริญญาเอก",
      });

      this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        name: name_th,
        name_th: name_th,
        start: dateStart,
        end: dateEnd,
        color: "rgb(42, 179, 163)",
        timed: !allDay,
        name_en: name_en,
        desc_th: desc_th,
        desc_en: desc_en,
        timeStart,
        timeEnd,
        types: "ปริญญาเอก",
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/calendarAcademics`,
        data,
        auth
      );
      console.log("calendarAcademics", response.data.data);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `Success`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$swal.fire({
          icon: "error",
          text: response.data.response_description,
          showConfirmButton: false,
          timer: 1500,
        });
      }

      this.getAllTable();
      // this.$router.go(0);
      // this.sendApi();
    },

    async editEvent(
      startDate,
      endDate,
      name_th,
      name_en,
      desc_th,
      desc_en,
      itemFilter,
      timeStart,
      timeEnd
    ) {
      // let checkIndex = this.events.filter((x) => x != itemFilter);
      // console.log("checkIndex", checkIndex);

      // this.events = checkIndex;

      // let dateStart = this.itemEdit.start;
      // let dateEnd = this.itemEdit.end;
      let dateStart = new Date(startDate);
      let dateEnd = new Date(endDate);
      const allDay = this.rnd(0, 3) === 0;

      this.events.push({
        name: name_th,
        name_th: name_th,
        start: dateStart,
        end: dateEnd,
        color: "rgb(42, 179, 163)",
        timed: !allDay,
        name_en: name_en,
        desc_th: desc_th,
        desc_en: desc_en,
        timeStart,
        timeEnd,
        types: "ปริญญาเอก",
      });

      let checkId = this.events.filter((x) => x.id === itemFilter.id);
      console.log("checkId", checkId[0].id);

      this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        name: name_th,
        name_th: name_th,
        start: dateStart,
        end: dateEnd,
        color: "rgb(42, 179, 163)",
        timed: !allDay,
        name_en: name_en,
        desc_th: desc_th,
        desc_en: desc_en,
        timeStart,
        timeEnd,
        types: "ปริญญาเอก",
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/calendarAcademics/${checkId[0].id}`,
        data,
        auth
      );
      console.log("calendarAcademics", response.data.data);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `Success`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$swal.fire({
          icon: "error",
          text: response.data.response_description,
          showConfirmButton: false,
          timer: 1500,
        });
      }

      // this.getAllTable();
      this.$router.go(0);
      // this.sendApi();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    onClickFile(val) {
      window.open(val, "_blank");
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    showPicture1(e) {
      // console.log('index',index);
      console.log("55");
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },

    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        this.loading = true;
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.imgUrl1 = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);

        this.loading = false;
      }
    },

    async submitAll(q_1_1) {
      await this.submitStudentAmount();
      await this.submitWelcomePopUp(q_1_1);
      setTimeout(() => this.$router.go(0), 1000);
    },

    async submitWelcomePopUp(pic) {
      await this.submitfileMain(pic);
      console.log("pic", pic);
      this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        title_th: this.title_th,
        // title_en: this.title_en,
        // desc_en: this.desc_en,
        // desc_th: this.desc_th,
        // desc: this.desc,
        img_url: this.imgUrl1,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/welcomePops/4`,
        data,
        auth
      );
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `บันทึกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$swal.fire({
          icon: "error",
          text: response.data.response_description,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    DeleteItems(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/carlendars/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          this.getAllType1();
          this.getAllType2();
          this.getAllType3();
        }
      });
    },

    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/banners/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
      // this.getAllbanner();
      this.loading = false;
    },

    async ViewBanner(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/banners/${val.id}`,
        auth
      );
      console.log("getOneBanner", response.data.data);
      this.itemBannerEdit = response.data.data;

      console.log(this.itemBannerEdit);
      this.method = "read";
      this.dialogBannerEdit = true;
    },

    openDialogCalendar() {
      this.dialogCalendar = true;
    },
    closeDialogCalendar() {
      this.dialogCalendar = false;
    },

    closeDialogEdit() {
      this.openDialogEdit = false;
    },

    closeDialog1() {
      this.openDialog1 = false;
    },
    closeDialog2() {
      this.openDialog2 = false;
    },
    closeDialog3() {
      this.openDialog3 = false;
    },
  },
};
</script>






<template>
  <div>
    <ManageCalendarPhD />
  </div>
</template>
<script>
import ManageCalendarPhD from "@/components/ManageCalendarPhD/ManageCalendarPhD";
export default {
  components: {
    ManageCalendarPhD,
  },
  created() {},
};
</script>
